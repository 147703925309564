import {
    SET_GENERATING,
    GENERATE_LICENSE,
    GENERATION_ERROR, SET_VALIDATING, VALIDATION_ERROR, VALIDATE_LICENSE
} from "../actions/types";

const initialState = {
    generating: false,
    error: null,
    validating: false,
    errorValidating: null,
    licenseValidatingResult: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GENERATE_LICENSE:
            return {
                ...state,
                generating: false,
                error: null
            };
        case GENERATION_ERROR:
            return {
                ...state,
                generating: false,
                error: action.payload
            };
        case SET_GENERATING:
            return {
                ...state,
                generating: true,
                error: null
            };
        case VALIDATE_LICENSE:
            return {
                ...state,
                licenseValidatingResult: action.payload,
                validating: false,
                errorValidating: null
            };
        case VALIDATION_ERROR:
            return {
                ...state,
                licenseValidatingResult: null,
                validating: false,
                errorValidating: action.payload
            };
        case SET_VALIDATING:
            return {
                ...state,
                licenseValidatingResult: null,
                validating: true,
                errorValidating: null
            };
        default:
            return state;
    }
}