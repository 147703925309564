import React from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {RAPIDMINER_ORANGE} from "../../util/Colors";

/**
 * A button to log out from the page.
 */
const LogoutButton = () => {
    const { logout } = useAuth0();

    const logOutClicked = e => {
        e.preventDefault();

        let logoutOptions = {
            returnTo: process.env.REACT_APP_LOGOUT_REDIRECT
        };

        logout(logoutOptions);
    };

    return (
        <a href="#!" onClick={logOutClicked}>
            <i style={{color: RAPIDMINER_ORANGE}} className="fas fa-sign-out-alt"/>{ } Logout
        </a>
    );
};

export default LogoutButton;