import {
    SIGN_EXTENSION,
    SIGNING_ERROR,
    SET_SIGNING,
    SET_SIGNING_UPLOAD_PERCENTAGE,
    SET_SIGNING_WAITING
} from "./types";
import axios from "axios";
import {getErrorsFromResponse} from "../util/ErrorHandling";

/** wait for extension signing processing in backend for 10 minutes */
const RETRY_TIME_LIMIT = 10 * 60 * 1000;
/** retry every 2 seconds */
const RETRY_INTERVAL = 2000;
/** calculated max retry attempts */
const RETRY_COUNTER_MAX = RETRY_TIME_LIMIT / RETRY_INTERVAL;

/**
 * Sign an extension and triggers a download once it has been signed. Will dispatch an error if something goes wrong.
 * @param file the file object itself to sign
 * @param jwtToken the access token
 */
export const signExtension = (file, jwtToken) => async dispatch => {
    try {
        dispatch(setSigning());

        // upload extension to backend
        const multipartFile = new FormData();
        multipartFile.set("file", file);
        const response = await axios.post(`/api/extensions/sign`, multipartFile, {
            timeout: 0, // no timeout due to potential slow network with large file upload
            onUploadProgress: progressEvent => dispatch(setProgress((progressEvent.loaded / progressEvent.total) * 100)),
            timeoutErrorMessage: "The server could not be reached. Please try again later.",
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `bearer ${jwtToken}`
            }
        });

        // we get a downloadId as a response, which we can use to download the signed extension once it is ready
        const { downloadId } = response.data;

        dispatch(setWaiting());

        // retry downloading for up to n minutes (see top of class for timeout value)
        let retryCounter = 0;
        let downloadedResponse;
        let checkIfDownloadIsAvailable = async () => {
            try {
                downloadedResponse = await axios.get(`/api/extensions/sign/${downloadId}`, {
                    responseType: "blob",
                    timeout: 0, // no timeout due to potential slow network on large file download. Also, Axios is stupid and kills active connections that transmit data...
                    timeoutErrorMessage: "The server could not be reached. Please try again later.",
                    headers: {
                        "Authorization": `bearer ${jwtToken}`
                    }
                });
            } catch (err) {
                if (err.response?.status === 417) {
                    // this means the extension is still being signed on the server, all good
                    retryCounter++;
                } else {
                    // some other error, we can stop retrying
                    retryCounter = -1;
                    dispatch({
                        type: SIGNING_ERROR,
                        payload: await getErrorsFromResponse(err)
                    });
                }
            }

            // either on failure or exceeded retry counts, we abort
            if (retryCounter === -1) {
                return;
            } else if (retryCounter > RETRY_COUNTER_MAX) {
                dispatch({
                    type: SIGNING_ERROR,
                    payload: {error: "The server could not process the extension in time. Please contact support."}
                });
                return;
            }

            // still retrying here

            if (!!downloadedResponse) {
                // if downloadResponse is set, we succeeded

                // trigger automatic download here
                const blob = new Blob([downloadedResponse.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute('download', file.name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                dispatch({
                    type: SIGN_EXTENSION
                });
            } else {
                // we need to retry, we did not succeed yet
                setTimeout(await checkIfDownloadIsAvailable, RETRY_INTERVAL);
            }
        }

        await checkIfDownloadIsAvailable();
    } catch (err) {
        dispatch({
            type: SIGNING_ERROR,
            payload: await getErrorsFromResponse(err)
        });
    }
};

/**
 * Set signing to true
 */
const setSigning = () => {
    return {
        type: SET_SIGNING
    };
};

const setProgress = percentage => {
    return {
        type: SET_SIGNING_UPLOAD_PERCENTAGE,
        payload: Math.round(percentage)
    }
}

const setWaiting = () => {
    return {
        type: SET_SIGNING_WAITING,
    }
}