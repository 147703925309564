import {
    SET_SIGNING,
    SIGN_EXTENSION,
    SIGNING_ERROR,
    SET_SIGNING_UPLOAD_PERCENTAGE,
    SET_SIGNING_WAITING
} from "../actions/types";

const initialState = {
    signing: false,
    waiting: false,
    uploadPercentage: 0,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SIGN_EXTENSION:
            return {
                ...state,
                signing: false,
                uploadPercentage: 0,
                waiting: false,
                error: null
            };
        case SIGNING_ERROR:
            return {
                ...state,
                error: action.payload,
                uploadPercentage: 0,
                waiting: false,
                signing: false
            };
        case SET_SIGNING:
            return {
                ...state,
                signing: true,
                uploadPercentage: 0,
                waiting: false,
                error: null
            };
        case SET_SIGNING_UPLOAD_PERCENTAGE:
            return {
                ...state,
                uploadPercentage: action.payload
            }
        case SET_SIGNING_WAITING:
            return {
                ...state,
                waiting: true
            }
        default:
            return state;
    }
}