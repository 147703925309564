import React from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {RAPIDMINER_ORANGE} from "../../util/Colors";

/**
 * A button to log into the page.
 */
const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    const logInClicked = async e => {
        e.preventDefault();
        await loginWithRedirect();
    };

    return (
        <a href="#!" onClick={logInClicked}>
            <i style={{color: RAPIDMINER_ORANGE}} className="fas fa-sign-in-alt"/> Login
        </a>
    );
};

export default LoginButton;