import React, {Fragment, useEffect, useState} from 'react';
import {Alert, Table} from "antd";
import PropTypes from "prop-types";
import {convertErrorObjectToArray} from "../../util/ErrorHandling";
import Search from "antd/lib/input/Search";

const SearchableTable = ({columns, data, loading, error, doSearch, emptyDataTextFiltered = "Data filtered out", emptyDataTextNoData = "No data"}) => {
    const [filter, setFilter] = useState("");
    const [searchTimer, setSearchTimer] = useState(-1);

    // do initial filter query
    useEffect(() => {
        doSearch(filter);
        // eslint-disable-next-line
    }, []);

    const errs = convertErrorObjectToArray(error);

    const emptyText = filter !== "" && !loading ? emptyDataTextFiltered : emptyDataTextNoData

    return (
        <Fragment>
            {errs.length > 0 &&
            <div style={{marginBottom: "0.75em"}}>{errs.map((err, index) => <Alert key={index} message={err} type="error"
                                                                                showIcon
                                                                                style={{marginBottom: "0.25em"}}/>)}</div>}
            <Search
                placeholder="Filter..."
                onSearch={doSearch}
                value={filter}
                onChange={e => {
                    // clear potentially pending search timer
                    clearTimeout(searchTimer);

                    const text = e.target.value;
                    setFilter(text);

                    // trigger new search timer with a slight delay to avoid too many network requests
                    setSearchTimer(setTimeout(() => {
                        doSearch(text);
                    }, 400));
                }}
                allowClear
                style={{marginBottom: "1em"}}
            />
            <Table
                columns={columns}
                pagination={{position: ["bottomRight"]}}
                dataSource={data}
                loading={loading}
                locale={{emptyText: emptyText}}
            />
        </Fragment>
    );
};

SearchableTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    doSearch: PropTypes.func.isRequired,
    emptyDataTextFiltered: PropTypes.string,
    emptyDataTextNoData: PropTypes.string
}


export default SearchableTable;