import {
    SET_GENERATING,
    GENERATE_LICENSE,
    GENERATION_ERROR,
    SET_VALIDATING,
    VALIDATE_LICENSE,
    VALIDATION_ERROR
} from "./types";
import axios from "axios";
import {getErrorsFromResponse} from "../util/ErrorHandling";

/**
 * Generate a development.lic and triggers a download once it has been generated. Will dispatch an error if something goes wrong.
 * @param licRequest the request containing information for whom to create the license
 * @param jwtToken the access token
 */
export const generateLicense = (licRequest, jwtToken) => async dispatch => {
    try {
        dispatch(setGenerating());

        const response = await axios.post("/api/license", licRequest, {
            responseType: "blob",
            timeout: 10000,
            timeoutErrorMessage: "The server could not be reached. Please try again later.",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${jwtToken}`
            }
        });

        // trigger automatic download here
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute('download', 'development.lic');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        dispatch({
            type: GENERATE_LICENSE
        });
    } catch (err) {
        dispatch({
            type: GENERATION_ERROR,
            payload: await getErrorsFromResponse(err)
        });
    }
};

/**
 * Validates a license. Will dispatch an error if something goes wrong.
 * @param licenseKey the license key
 * @param jwtToken the access token
 */
export const validateLicense = (licenseKey, jwtToken) => async dispatch => {
    try {
        dispatch(setValidating());

        const response = await axios.post("/api/license/validate", licenseKey, {
            timeout: 10000,
            timeoutErrorMessage: "The server could not be reached. Please try again later.",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${jwtToken}`
            }
        });

        dispatch({
            type: VALIDATE_LICENSE,
            payload: response.data
        });
    } catch (err) {
        dispatch({
            type: VALIDATION_ERROR,
            payload: await getErrorsFromResponse(err)
        });
    }
};

/**
 * Set generating to true
 */
const setGenerating = () => {
    return {
        type: SET_GENERATING
    };
};

/**
 * Set validating to true
 */
const setValidating = () => {
    return {
        type: SET_VALIDATING
    };
};