import React, {Fragment, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {Alert, Button, Card, Col, Row, Space, Spin} from "antd";
import {
    CustomerServiceTwoTone,
    FileTextTwoTone,
    LoadingOutlined,
    SafetyCertificateTwoTone,
    BookTwoTone,
    SecurityScanTwoTone
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import PageSkeleton from "./PageSkeleton";
import LogoutButton from "../navigation/LogoutButton";
import {hasSufficientPermissions, isEngineeringAdmin, hasAccessTo24x7Support} from "../../util/Permissions";
import {Link} from "react-router-dom";
import {RAPIDMINER_ORANGE} from "../../util/Colors";
import Title from "antd/lib/typography/Title";

/**
 * Main landing page.
 */
const Home = () => {
    const {loginWithRedirect, isAuthenticated, isLoading, user, error} = useAuth0();
    const [loggingIn, setLoggingIn] = useState(false);

    const loginClicked = e => {
        setLoggingIn(true);
        return loginWithRedirect();
    };


    // not logged in landing page
    if (!isAuthenticated) {
        return (
            <Fragment>
                <Row justify="center">
                    <Col>
                        <Card style={{textAlign: "center", margin: "5em"}}>
                            <Space direction="vertical" size="large">
                                <Fragment>
                                    <Alert message="Please log in to get started!" description="You need to log in with your RapidMiner account before you can access this page." type="info" showIcon style={{textAlign: "left"}}/>
                                    {loggingIn || isLoading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : <Button size="large" type="primary" onClick={loginClicked}>Log in now</Button>}
                                </Fragment>
                            </Space>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }

    // insufficient permissions landing page
    if (!hasSufficientPermissions(user)) {
        return (
            <Row justify="center">
                <Col>
                    <Card style={{textAlign: "center", margin: "5em"}}>
                        <Space direction="vertical" size="large">
                            <Fragment>
                                <Alert message="Insufficient Permissions!" description="Your account does not have permissions to access this site." type="error" showIcon style={{textAlign: "left"}}/>
                                <LogoutButton/>
                            </Fragment>
                        </Space>
                    </Card>
                </Col>
            </Row>
        );
    }

    // login failed, retry landing page
    if (error) {
        return (
            <Fragment>
                <Row justify="center">
                    <Col>
                        <Card style={{textAlign: "center", margin: "5em"}}>
                            <Space direction="vertical" size="large">
                                <Fragment>
                                    <Alert message="Something went wrong when trying to log in!" description={`${error}`} type="error" showIcon style={{textAlign: "left"}}/>
                                    {loggingIn || isLoading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : <Button size="large" type="primary" onClick={loginClicked}>Try again</Button>}
                                </Fragment>
                            </Space>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }

    // actual landing page
    const cardStyle = {height: "100%"};
    return (
        <Fragment>
            <PageSkeleton
                title="RapidMiner Engineering Tools"
                description="This page serves as your hub for all the mundane tasks you would've had to ping Engineering for in the past. Now those things are just one click away, and always available right when you need them!"
            >
                <Space direction="vertical" size={25}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Link to="/sign-extension">
                                <Card style={cardStyle} bordered={false} hoverable={true} cover={<span style={{fontSize: "3em", textAlign: "center", paddingTop: "25px"}}><SafetyCertificateTwoTone twoToneColor={RAPIDMINER_ORANGE}/></span>}>
                                    <Card.Meta style={{textAlign: "center"}} title="Sign Extension" description="Sign your extension with the RapidMiner code signing certificate." />
                                </Card>
                            </Link>
                        </Col>
                        <Col span={8}>
                            <Link to="/generate-development-lic">
                                <Card style={cardStyle} bordered={false} hoverable={true} cover={<span style={{fontSize: "3em", textAlign: "center", paddingTop: "25px"}}><FileTextTwoTone twoToneColor={RAPIDMINER_ORANGE}/></span>}>
                                    <Card.Meta style={{textAlign: "center"}} title="Generate development.lic" description="Create a development.lic file for working with the RapidMiner Studio codebase." />
                                </Card>
                            </Link>
                        </Col>
                        {hasAccessTo24x7Support(user) && <Col span={8}>
                            <Link to="/validate-license">
                                <Card style={cardStyle} bordered={false} hoverable={true} cover={<span style={{fontSize: "3em", textAlign: "center", paddingTop: "25px"}}><SecurityScanTwoTone twoToneColor={RAPIDMINER_ORANGE}/></span>}>
                                    <Card.Meta style={{textAlign: "center"}} title="Inspect License Key" description="Check the license key for any of our products to see what the license is for, and what it contains." />
                                </Card>
                            </Link>
                        </Col>}
                    </Row>
                <Row gutter={24}>
                    {hasAccessTo24x7Support(user) && <Col span={8}>
                        <a href="https://support-pushover.rapidminer.com/" target="_blank" rel="noopener noreferrer">
                            <Card style={cardStyle} bordered={false} hoverable={true} cover={<span style={{fontSize: "3em", textAlign: "center", paddingTop: "25px"}}><CustomerServiceTwoTone twoToneColor={RAPIDMINER_ORANGE}/></span>}>
                                <Card.Meta style={{textAlign: "center"}} title="24x7 Support*" description="Contact 2nd & 3rd level engineering support, available 24x7 every day." />
                            </Card>
                        </a>
                        {hasAccessTo24x7Support(user) && <Paragraph style={{textAlign: "right", fontSize: "small"}}>* Credentials can be found <a href="https://kb.rapidminer.com/display/COMINFO/24x7+Engineering+Support" target="_blank" rel="noopener noreferrer">here</a></Paragraph>}
                    </Col>}
                </Row>
                </Space>

                {isEngineeringAdmin(user) && <Fragment>
                    <Title level={2} style={{marginTop: "50px"}}>
                        Administration
                    </Title>
                    <Paragraph>
                        This section is only accessible for administrators.
                    </Paragraph>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Link to="/audit-history">
                                <Card style={cardStyle} bordered={false} hoverable={true} cover={<span style={{fontSize: "3em", textAlign: "center", paddingTop: "25px"}}><BookTwoTone twoToneColor={RAPIDMINER_ORANGE}/></span>}>
                                    <Card.Meta style={{textAlign: "center"}} title="Audit History" description="View past events like generated licenses and signed extensions." />
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </Fragment>}

                <Paragraph style={{marginTop: "5em"}}>
                    If you are missing some functionality on this page, or have any other suggestions,
                    please <a href="mailto:mboeck@rapidminer.com">let us know</a>!
                </Paragraph>
            </PageSkeleton>
        </Fragment>
    );
};

export default Home;