import React from 'react';
import PageSkeleton from "./PageSkeleton";
import {Tabs} from "antd";
import DevLicHistory from "../fragments/DevLicHistory";
import SignedExtensionHistory from "../fragments/SignedExtensionHistory";

const AuditHistory = () => {

    return (
        <PageSkeleton
            title="Audit History"
            description="View past events like generated development licenses, as well as extension signing events. This page can only be accessed by administrators."
        >
            <Tabs type="line">
                <Tabs.TabPane tab="Generated Licenses" key="audit-development-licenses">
                    <DevLicHistory/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Signed Extensions" key="audit-signed-extensions">
                    <SignedExtensionHistory/>
                </Tabs.TabPane>
            </Tabs>
        </PageSkeleton>
    );
};

export default AuditHistory;