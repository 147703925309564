import React, {Fragment} from 'react';
import PropTypes from "prop-types";
import {Descriptions, Divider, Row, Tag, Tooltip} from "antd";

const LicenseDetails = ({result}) => {
    const {valid, validationError, licenseDetails} = result;

    if (!valid) {
        return (
            <Fragment>
                <Divider>Validation Results</Divider>
                <Row justify={"center"}>
                    <Tooltip title="This license key is invalid.">
                        <Tag color="#fd5200cc">Invalid license</Tag>
                    </Tooltip>
                    {!!validationError && `${validationError}`}
                </Row>
            </Fragment>
        );
    }

    let expired = false;
    let startsInFuture = false;
    let oldLicense = false;
    let compVersionStr = "";
    let holderProps = "";
    let constraintProps = {};
    let startDateStr = "-";
    let endDateStr = "forever";
    let annotationStr = "-";
    if (!!licenseDetails) {
        const {productId, compatibleProductVersions, validFrom, validUntil, holderProperties, annotations, constraints} = licenseDetails;

        if (!!validFrom) {
            startsInFuture = new Date(validFrom).getTime() > Date.now();

            const d = new Date(validFrom);
            startDateStr = `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
        }
        if (!!validUntil) {
            expired = new Date(validUntil).getTime() < Date.now();

            const d = new Date(validUntil);
            endDateStr = `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
        }
        if (!!holderProperties) {
            for (const p in holderProperties) {
                holderProps += `${p}: ${holderProperties[p]}, `;
            }

            if (holderProps.endsWith(", ")) {
                holderProps = holderProps.slice(0, -2);
            }
        }
        if (!!annotations) {
            annotationStr = annotations;
        }
        if (!!constraints) {
            constraintProps = constraints;
        }

        if (compatibleProductVersions.length === 0) {
            if (productId === "rapidminer-server") {
                compVersionStr = "2.0 - 2.4";
                oldLicense = true;
            } else if (productId === "rapidminer-studio") {
                compVersionStr = "6.0 - 6.4";
                oldLicense = true;
            }
        } else {
            compVersionStr = compatibleProductVersions;
        }
    }

    return (
        <Fragment>
            <Divider>Validation Results</Divider>

            <Row justify={"center"}>
                {(!expired && !startsInFuture) && <Tooltip title="This license key is both valid and currently active."><Tag color="#87d068">Active license</Tag></Tooltip>}
                {expired && <Tooltip title="This license key is valid, but it has already expired."><Tag color="#CEB496">Expired license</Tag></Tooltip>}
                {startsInFuture && <Tooltip title="This license key is valid, but its activation date is in the future."><Tag color="#2db7f5">Upcoming license</Tag></Tooltip>}
                {(!expired && !startsInFuture) && "This is a valid and active license."}
                {expired && "This is a valid, but expired license."}
                {startsInFuture && "This is an upcoming license."}
            </Row>

            <Row style={{marginTop: "50px"}}>
                <Descriptions>
                    <Descriptions.Item label="Product" style={{fontWeight: "bold"}}>{licenseDetails.productId}</Descriptions.Item>
                    <Descriptions.Item label="Edition" style={{fontWeight: "bold"}}>{licenseDetails.productEdition}</Descriptions.Item>
                    <Descriptions.Item label="Compatible Versions" style={{fontWeight: "bold"}}>{compVersionStr}&nbsp;{oldLicense && <Tooltip title="This license is for an outdated version of this product."><i style={{color: "#bfbf00"}} className="fas fa-exclamation-triangle"/></Tooltip>}</Descriptions.Item>

                    <Descriptions.Item label="Licensee Name" style={{fontWeight: "bold"}}>{licenseDetails.holderName}</Descriptions.Item>
                    <Descriptions.Item label="Licensee Email" style={{fontWeight: "bold"}}>{licenseDetails.holderEmail}</Descriptions.Item>
                    <Descriptions.Item label="Licensee Additional Data" style={{fontWeight: "bold"}}>{!!holderProps ? holderProps : "-"}</Descriptions.Item>

                    <Descriptions.Item label="Valid From" style={{fontWeight: "bold"}}>
                        {startDateStr}&nbsp;{startsInFuture && <Tooltip title="This license is not yet active."><i style={{color: "#bfbf00"}} className="fas fa-exclamation-triangle"/></Tooltip>}
                    </Descriptions.Item>
                    <Descriptions.Item label="Valid Until" style={{fontWeight: "bold"}}>
                        {endDateStr}&nbsp;{expired && <Tooltip title="This license has already expired."><i style={{color: "#fd5200cc"}} className="fas fa-exclamation-triangle"/></Tooltip>}
                    </Descriptions.Item>
                    <Descriptions.Item label="Annotations" style={{fontWeight: "bold"}}>{annotationStr}</Descriptions.Item>
                    { Object.entries(constraintProps).map((t,k) => <Descriptions.Item label={`Constraint ${t[0]}`} style={{fontWeight: "bold"}}>{t[1]}</Descriptions.Item>) }
                </Descriptions>
            </Row>
        </Fragment>
    );
};

LicenseDetails.propTypes = {
    result: PropTypes.object.isRequired
}

export default LicenseDetails;