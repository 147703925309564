import React, {Fragment, useState} from 'react';
import {Alert, Button, Checkbox, Form, message, Progress, Spin, Upload} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import PageSkeleton from "./PageSkeleton";
import Title from "antd/lib/typography/Title";
import {InboxOutlined, LoadingOutlined} from "@ant-design/icons";
import {signExtension} from "../../actions/signExtensionActions";
import {convertErrorObjectToArray} from "../../util/ErrorHandling";
import {useAuth0} from "@auth0/auth0-react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

/**
 * Page to sign extension .jar files.
 */
const SignExtension = ({signExtension, extSign: {signing, waiting, error, uploadPercentage}}) => {
    const {getAccessTokenSilently} = useAuth0();
    const [fileListCustom, setFileListCustom] = useState([]);
    const [usedSecurityStandards, setUsedSecurityStandards] = useState(false);
    const [allowAuditing, setAllowAuditing] = useState(false);

    const props = {
        name: "file",
        accept: ".jar",
        headers: {
            authorization: "authorization-text",
        },
        onRemove: file => {
            setFileListCustom([]);
        },
        beforeUpload: file => {
            setFileListCustom([file]);
            return false;
        },
        fileList: fileListCustom
    };

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 14,
        },
    };

    const onSubmit = async e => {
        if (fileListCustom.length === 0) {
            message.error("No extension file selected!");
        } else {
            const file = fileListCustom[0];
            if (!file.name.endsWith(".jar")) {
                message.error("You must select a .jar file to sign!");
                e.preventDefault();
            } else {
                signExtension(file, await getAccessTokenSilently());
            }
        }
    };

    const errs = convertErrorObjectToArray(error);

    return (
        <PageSkeleton
            title="Sign an Extension"
            description="Sign your extension with the RapidMiner code signing certificate."
        >
            <Paragraph>
                Extensions are by default restricted in what they are allowed to do within RapidMiner Studio. To grant your extension additional permissions, it needs to be signed with the RapidMiner code signing certificate.
                <br/>
                See <a href="https://docs.rapidminer.com/latest/developers/extensions/security/" target="_blank"
                       rel="noopener noreferrer">public documentation</a> for more details.
            </Paragraph>
            <Title level={3}>Upload the extension you want to sign:</Title>

            <Form {...formItemLayout} name="upload-extension" onSubmitCapture={onSubmit}>
                <Form.Item name="dragger" rules={[
                    {
                        required: true,
                        message: 'Please select the extension .jar file to sign!',
                    },
                ]}>
                    <Upload.Dragger {...props} name="files">
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click here, or drag the extension to this area to upload</p>
                        <p className="ant-upload-hint">The extension must be in the <strong>.jar</strong> file format for signing.
                        </p>
                    </Upload.Dragger>
                </Form.Item>
                {signing && <Fragment>Uploading:<Progress percent={uploadPercentage} type="circle" width={50} style={{marginBottom: "2em", marginLeft: "2em", marginRight: "2em"}}/></Fragment>}
                {waiting && <Fragment>Processing:<Spin indicator={<LoadingOutlined style={{ marginLeft: "0.5em", fontSize: 48 }} spin />} /></Fragment>}
                <Form.Item validateStatus={usedSecurityStandards ? "success" : "error"}>
                    <Checkbox checked={usedSecurityStandards} onChange={e => setUsedSecurityStandards(e.target.checked)}>
                        I hereby confirm that this extension adheres to the RapidMiner security and coding standards and does not contain any malicious code.
                    </Checkbox>
                </Form.Item>
                <Form.Item validateStatus={allowAuditing ? "success" : "error"}>
                    <Checkbox checked={allowAuditing} onChange={e => setAllowAuditing(e.target.checked)}>
                        I understand and accept that this signing procedure and details of the extension itself are stored for auditing purposes.
                    </Checkbox>
                </Form.Item>

                {errs.length > 0 && <div style={{marginBottom: "2em"}}>{errs.map((err, index) => <Alert key={index} message={err} type="error" showIcon style={{marginBottom: "0.25em"}}/>)}</div>}
                <Form.Item>
                    <Button type="primary" loading={signing} disabled={!(usedSecurityStandards && allowAuditing)} htmlType="submit" >Sign Extension Now</Button>
                </Form.Item>
            </Form>
        </PageSkeleton>
    );
};

SignExtension.propTypes = {
    signExtension: PropTypes.func.isRequired,
    extSign: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    extSign: state.signExtension
});

export default connect(mapStateToProps, {signExtension})(SignExtension);