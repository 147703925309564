const ENG_ADMIN = "RM_ENGINEERING_ADMIN";
const ENG = "RM_ENGINEERING";
const PRODUCT = "RM_PROD";
const DEVOPS = "RM_DEVOPS";
const FIELD = "RM_FIELD";
const OEM_DEVLIC = "OEM_DEVLIC";
const OEM_SIGN = "OEM_SIGN";
const ALL_ROLES = [ENG_ADMIN, ENG, PRODUCT, FIELD, DEVOPS, OEM_DEVLIC, OEM_SIGN];

const RAPIDMINER_EMAIL = "@rapidminer.com";


const hasAtLeastOneAuthorizedRole = (user, validRoles) => {
    const namespace = user["https://engineering-tools.rapidminer.com"];
    if (!namespace || !namespace.roles) {
        return false;
    }
    const roleArray = [];
    for (const xKey in namespace.roles) {
        roleArray.push(namespace.roles[xKey]);
    }

    return roleArray.find(role => validRoles.includes(role));
}

const hasRapidMinerEmail = user => {
    return user.email.endsWith(RAPIDMINER_EMAIL);
}

/**
 * Checks whether the user exists and if so, whether he has sufficient permissions to access this app.
 * @param user the user, can be undefined
 * @return {*|boolean} true if user is defined and has at least one eligible role; false if he is undefined or has no eligible roles
 */
export const hasSufficientPermissions = user => {
    return !!user && hasAtLeastOneAuthorizedRole(user, ALL_ROLES);
}

/**
 * Checks whether the user has access to 24x7 support. ALL users with an email ending with @rapidminer.com have, no need for any special role!
 * @param user the user, can be undefined
 * @return {boolean|*} true if user is defined and has access to 24x7 support; false otherwise
 */
export const hasAccessTo24x7Support = user => {
    return !!user && hasRapidMinerEmail(user);
}

/**
 * Checks whether the given user is an engineering admin.
 * @param user the user, can be undefined
 * @return {*|boolean} true if user has  eligible roles; false if he has eligible roles
 */
export const isEngineeringAdmin = user => {
    return !!user && hasAtLeastOneAuthorizedRole(user, [ENG_ADMIN]);
}

