import React from 'react';
import {Col, Row} from "antd";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
import PropTypes from "prop-types";
import {RAPIDMINER_ORANGE} from "../../util/Colors";

/**
 * A skeleton HOC which is used to give subpages the same appearance and layout.
 * @param title the title of the page
 * @param description a short description of it
 * @param children the child elements which actually compose the page
 */
const PageSkeleton = ({title, description, children}) => {
        return (
            <Row justify="left">
                <Col span={16} offset={4}>
                    <Title level={2} style={{color: RAPIDMINER_ORANGE, margin: "1em 0em 0.5em 0em", fontFamily: "Open Sans, arial, sans-serif"}}>
                        {title}
                    </Title>
                    <Paragraph style={{color: "gray", fontSize: "1.1em", fontFamily: "Open Sans, arial, sans-serif", marginBottom: "5em"}}>
                        {description}
                    </Paragraph>
                    {children}
                </Col>
            </Row>
        );
};

PageSkeleton.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
}

export default PageSkeleton;