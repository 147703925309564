import React, {Fragment} from 'react';
import {Button, Modal, Tag} from "antd";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getGeneratedLicenses} from "../../actions/auditActions";
import {useAuth0} from "@auth0/auth0-react";
import Title from "antd/lib/typography/Title";
import SearchableTable from "./SearchableTable";

const DevLicHistory = ({getGeneratedLicenses, audit: {licenses, loadingLicenses, errorLicenses}}) => {
    const {getAccessTokenSilently} = useAuth0();
    const columns = [
        {
            title: "Name",
            dataIndex: "fullName",
            key: "fullName",
            width: "20%",
            sorter: {
                compare: (a, b) => a.fullName.localeCompare(b.fullName)
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: {
                compare: (a, b) => a.email.localeCompare(b.email)
            }
        },
        {
            title: "Valid For Days",
            dataIndex: "validForDays",
            key: "validForDays",
            sorter: {
                compare: (a, b) => a.validForDays - b.validForDays
            },
            align: "center",
            render: period => {
                const color = (period === 365 ? "#2db7f5" : (period > 365 ? "#f50" : "#87d068"));
                return (
                    <Tag style={{width: "3em", textAlign: "center"}} color={color}>
                        {period}
                    </Tag>
                )
            }
        },
        {
            title: "Created",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: {
                compare: (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt)
            },
            render: created => {
                const d = new Date(created);
                return (
                    <span>{d.toLocaleDateString()} {d.toLocaleTimeString()}</span>
                )
            }
        },
        {
            title: "More Info",
            dataIndex: "licenseId",
            key: "info",
            render: licId => (
                <Button type="link" onClick={() => {
                    const clickedLic = licenses.find(license => license.licenseId === licId);
                    const d = new Date(clickedLic.createdAt);
                    Modal.info({
                        title: 'License Details:',
                        content: (
                            <div>
                                <div style={{fontSize: "small", marginTop: "2em", marginBottom: "0.4em"}}><strong>Name:</strong> {clickedLic.fullName}</div>
                                <div style={{fontSize: "small", marginBottom: "0.4em"}}><strong>Email:</strong> {clickedLic.email}</div>
                                <div style={{fontSize: "small", marginBottom: "0.4em"}}><strong>Created:</strong> <span>{d.toLocaleDateString()} {d.toLocaleTimeString()}</span></div>
                                <div style={{fontSize: "small", marginBottom: "0.4em"}}><strong>Validity:</strong> {clickedLic.validForDays} {clickedLic.validForDays === 1 ? "day" :"days"}</div>
                                <div style={{fontSize: "small", marginBottom: "0.4em"}}><strong>License ID:</strong> {clickedLic.licenseId}</div>
                                <div style={{fontSize: "small", marginBottom: "1em"}}><strong>Created By:</strong> {clickedLic.createdBy}</div>
                            </div>
                        ),
                        onOk() {},
                    });
                }}>View Details</Button>
            )
        }
    ];

    const doSearch = async search => {
        getGeneratedLicenses(search, await getAccessTokenSilently());
    };

    return (
        <Fragment>
            <Title level={3}>
                Generated Development Licenses
            </Title>

            <SearchableTable columns={columns} data={licenses} doSearch={doSearch} loading={loadingLicenses} error={errorLicenses} emptyDataTextFiltered="All licenses filtered out" emptyDataTextNoData="No licenses generated yet"/>
        </Fragment>
    );
};

DevLicHistory.propTypes = {
    getGeneratedLicenses: PropTypes.func.isRequired,
    audit: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    audit: state.audit
});

export default connect(mapStateToProps, {getGeneratedLicenses})(DevLicHistory);