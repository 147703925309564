import React, {useState} from 'react';
import PageSkeleton from "./PageSkeleton";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import {Alert, Button, Checkbox, Form, Input} from "antd";
import {useAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {generateLicense} from "../../actions/licenseActions";
import {convertErrorObjectToArray} from "../../util/ErrorHandling";


/**
 * Page to generate a development.lic file.
 */
const GenerateDevLic = ({generateLicense, license: {generating, error}}) => {
    const {user, getAccessTokenSilently} = useAuth0();
    const [allowAuditing, setAllowAuditing] = useState(false);

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 14,
        },
    };

    const onFinish = async values => {
        generateLicense({
            fullName: values.fullName,
            email: values.email,
            validForDays: values.validity,
        }, await getAccessTokenSilently());
    };

    const devLicStyle = {fontStyle: "italic"};

    const errs = convertErrorObjectToArray(error);

    return (
        <PageSkeleton
            title="Generate development.lic"
            description="Create your own development.lic file for working with the RapidMiner Studio codebase."
        >
            <Paragraph>
                To work with RapidMiner Studio via an IDE, a special little file ( "<span style={devLicStyle}>development.lic</span>` ) is required.
                This has nothing to do with the RapidMiner Studio license, but it rather is a token showing that the owner is permitted to work with the unsecured codebase of Studio.
                <br/>
                Without this file, anyone could circumvent the license restrictions and run Studio from an IDE directly.
            </Paragraph>
            <Paragraph>
                The <span style={devLicStyle}>development.lic</span> has to be placed as-is directly inside your <span style={devLicStyle}>.RapidMiner/licenses</span> folder. It will always be valid for one year (365 days), after which it expires and needs to be re-created.
                <br/>
                See <a href="https://kb.rapidminer.com/display/RMSTUDIO/JAR+Verification/" target="_blank" rel="noopener noreferrer">internal documentation</a> for technical details.
            </Paragraph>
            <Title level={3}>Generate license:</Title>

            <Form {...formItemLayout} name="generate-dev-lic" onFinish={onFinish} initialValues={{
                email: user.email,
                validity: 365
            }}>
                <Form.Item label="Full name" style={{marginLeft: "0px"}} name="fullName" rules={[
                    {
                        required: true,
                        message: 'Please enter the full name of the license holder!',
                    }
                ]}>
                    <Input autoFocus disabled={generating}/>
                </Form.Item>
                <Form.Item label="Email" name="email" rules={[
                    {
                        required: true,
                        message: 'Please enter the email of the license holder!',
                    },
                    {
                        type: "email",
                        message: "Please enter a valid email address!"
                    }
                ]}>
                    <Input disabled={generating} />
                </Form.Item>
                <Form.Item label="Valid for Days" name="validity" rules={[
                    {
                        required: true,
                        message: 'Please enter the number of days the license should be valid!',
                    }
                ]}>
                    <Input type="number" disabled={generating} min={1} max={365}/>
                </Form.Item>
                <Form.Item validateStatus={allowAuditing ? "success" : "error"}>
                    <Checkbox checked={allowAuditing} onChange={e => setAllowAuditing(e.target.checked)} disabled={generating}>
                        I understand and accept that the license generation is stored for auditing purposes.
                    </Checkbox>
                </Form.Item>

                {errs.length > 0 && <div style={{marginBottom: "2em"}}>{errs.map((err, index) => <Alert key={index} message={err} type="error" showIcon style={{marginBottom: "0.25em"}}/>)}</div>}
                <Form.Item>
                    <Button type="primary" loading={generating} disabled={!allowAuditing} htmlType="submit" >Generate Development License</Button>
                </Form.Item>
            </Form>
        </PageSkeleton>
    );
};

GenerateDevLic.propTypes = {
    generateLicense: PropTypes.func.isRequired,
    license: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    license: state.license
});

export default connect(mapStateToProps, {generateLicense})(GenerateDevLic);