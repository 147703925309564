import React from 'react';
import {Button, Result} from "antd";
import {QuestionCircleTwoTone} from "@ant-design/icons";
import {RAPIDMINER_ORANGE} from "../../util/Colors";
import {Link} from "react-router-dom";

/**
 * 404 page.
 */
const NotFound = () => {
    return (
        <Result
            title="404"
            icon={<QuestionCircleTwoTone twoToneColor={RAPIDMINER_ORANGE}/>}
            subTitle="Sorry, the page you requested does not exist."
            extra={<Link to="/"><Button type="primary">Back Home</Button></Link>}
        />
    );
};

export default NotFound;