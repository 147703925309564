import React, {useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {Link, withRouter} from "react-router-dom";
import {Menu, Spin} from "antd";
import {
    HomeTwoTone,
    SafetyCertificateTwoTone,
    FileTextTwoTone,
    LoadingOutlined,
    CustomerServiceTwoTone,
    BookTwoTone,
    SecurityScanTwoTone
} from "@ant-design/icons";
import LoginButton from "./LoginButton";
import ProfileContextMenu from "./ProfileContextMenu";
import {hasSufficientPermissions, isEngineeringAdmin, hasAccessTo24x7Support} from "../../util/Permissions";
import {RAPIDMINER_ORANGE} from "../../util/Colors";

/**
 * The top navigation menu bar.
 */
const Navbar = ({location, history}) => {
    const { isAuthenticated, isLoading, user } = useAuth0();

    const updateCurLoc = (pathname) => {
        let curKey = pathname;
        if (pathname === "/") {
            curKey = "home";
        }

        return curKey;
    }

    history.listen((location, action) => {
        if (action === "PUSH") {
            setCurrent(updateCurLoc(location.pathname));
        }
    });

    // make sure that highlighted nav item in menu bar matches URL user arrived on
    const [current, setCurrent] = useState(updateCurLoc(location.pathname));

    const onClick = e => {
        if (e.key === "login") {
            setCurrent("home");
            history.push("/");
        } else if (e.key !== "profile" && e.key !== "24-7") {
            setCurrent(e.key);
        }
    };

    return (
        <Menu mode="horizontal" onClick={onClick} selectedKeys={current} >
            <Menu.Item key="home" icon={<HomeTwoTone twoToneColor={RAPIDMINER_ORANGE}/>}>
                <Link to="/">Home</Link>
            </Menu.Item>
            {isAuthenticated && hasSufficientPermissions(user) && (<Menu.Item key="sign-extension" icon={<SafetyCertificateTwoTone twoToneColor={RAPIDMINER_ORANGE}/>}>
                <Link to="/sign-extension">Sign Extension</Link>
            </Menu.Item>)}
            {isAuthenticated && hasSufficientPermissions(user) && (<Menu.Item key="generate-development-lic" icon={<FileTextTwoTone twoToneColor={RAPIDMINER_ORANGE}/>}>
                <Link to="/generate-development-lic">Generate development.lic</Link>
            </Menu.Item>)}
            {isAuthenticated && hasSufficientPermissions(user) && (<Menu.Item key="validate-license" icon={<SecurityScanTwoTone twoToneColor={RAPIDMINER_ORANGE}/>}>
                <Link to="/validate-license">Inspect License Key</Link>
            </Menu.Item>)}
            {isAuthenticated && isEngineeringAdmin(user) && (<Menu.Item key="audit" icon={<BookTwoTone twoToneColor={RAPIDMINER_ORANGE}/>}>
                <Link to="/audit-history">Audit History</Link>
            </Menu.Item>)}
            {isAuthenticated && hasAccessTo24x7Support(user) && (<Menu.Item key="24-7" icon={<CustomerServiceTwoTone twoToneColor={RAPIDMINER_ORANGE}/>}>
                <a href="https://support-pushover.rapidminer.com/" target="_blank" rel="noopener noreferrer">24x7 Support</a>
            </Menu.Item>)}
            {!isAuthenticated && (<Menu.Item key="login" style={{float: 'right'}}>
                {isLoading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : <LoginButton />}
            </Menu.Item>)}
            {isAuthenticated && (<Menu.Item key="profile" style={{float: 'right'}}>
                <ProfileContextMenu/>
            </Menu.Item>)}

        </Menu>
    );
};

export default withRouter(Navbar);