import React, {Fragment} from 'react';
import {Route, Redirect} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {isEngineeringAdmin} from "../../util/Permissions";
import {Alert, Card, Col, Row, Space, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

/**
 * Routing mechanism to make sure any error/unauthorized etc states go back to the main landing page.
 * @param Component the component to hide behind the engineering admin route
 * @param rest all other parameters for a component are forwarded here
 */
const EngAdminRoute = ({component: Component, ...rest}) => {
    const {isAuthenticated, isLoading, user, error} = useAuth0();

    if (isLoading) {
        return (
            <Fragment>
                <Row justify="center">
                    <Col>
                        <Card style={{textAlign: "center", margin: "5em"}}>
                            <Space direction="vertical" size="large">
                                <Fragment>
                                    <Alert message="Please wait" description="Checking login status..." type="info" showIcon style={{textAlign: "left"}}/>
                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                                </Fragment>
                            </Space>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }

    return (
        <Route {...rest} render={props => !isAuthenticated || error || !isEngineeringAdmin(user) ? (
            <Redirect to="/" />
        ) : (
            <Component {...props} />
        )}/>
    );
};

export default EngAdminRoute;