import {
    SET_AUDIT_LICENSES,
    AUDIT_LICENSES_ERROR,
    AUDIT_LICENSES_LOADING,
    SET_AUDIT_SIGNED_EXTENSIONS,
    AUDIT_SIGNED_EXTENSIONS_ERROR,
    AUDIT_SIGNED_EXTENSIONS_LOADING
} from "../actions/types";

const initialState = {
    licenses: [],
    signedExtensions: [],
    loadingLicenses: false,
    errorLicenses: null,
    loadingSignedExtensions: false,
    errorSignedExtensions: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_AUDIT_LICENSES:
            return {
                ...state,
                licenses: action.payload,
                loadingLicenses: false,
                errorLicenses: null
            };
        case AUDIT_LICENSES_ERROR:
            return {
                ...state,
                licenses: [],
                loadingLicenses: false,
                errorLicenses: action.payload
            };
        case AUDIT_LICENSES_LOADING:
            return {
                ...state,
                loadingLicenses: true,
                errorLicenses: null
            };
        case SET_AUDIT_SIGNED_EXTENSIONS:
            return {
                ...state,
                signedExtensions: action.payload,
                loadingSignedExtensions: false,
                errorSignedExtensions: null
            };
        case AUDIT_SIGNED_EXTENSIONS_ERROR:
            return {
                ...state,
                signedExtensions: [],
                loadingSignedExtensions: false,
                errorSignedExtensions: action.payload
            };
        case AUDIT_SIGNED_EXTENSIONS_LOADING:
            return {
                ...state,
                loadingSignedExtensions: true,
                errorSignedExtensions: null
            };
        default:
            return state;
    }
}