import React from 'react';
import PageSkeleton from "./PageSkeleton";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import {Alert, Button, Form} from "antd";
import {useAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {validateLicense} from "../../actions/licenseActions";
import {convertErrorObjectToArray} from "../../util/ErrorHandling";
import TextArea from "antd/lib/input/TextArea";
import LicenseDetails from "../fragments/LicenseDetails";


/**
 * Page to validate a license key.
 */
const ValidateLicense = ({validateLicense, license: {licenseValidatingResult, validating, errorValidating}}) => {
    const {getAccessTokenSilently} = useAuth0();

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 14,
        },
    };

    const onFinish = async key => {
        validateLicense(key, await getAccessTokenSilently());
    };

    const errs = convertErrorObjectToArray(errorValidating);

    return (
        <PageSkeleton
            title="Inspect License Key"
            description="Inspect the license key for any of our products."
        >
            <Paragraph>
                If you have a license key and are not quite sure what it is for or what exactly it contains, paste it here to check its contents. The license key is a long sequence of characters, usually starting with <span style={{fontStyle: "italic"}}>H4sIAAAAAAAAA[...]</span>.
            </Paragraph>
            <Title level={3}>Inspect license:</Title>

            <Form {...formItemLayout} name="validate-license" onFinish={onFinish} initialValues={{
                licenseKey: !!licenseValidatingResult ? licenseValidatingResult.licenseKey : "",
            }}>
                <Form.Item label="License Key" style={{marginLeft: "0px"}} name="licenseKey" rules={[
                    {
                        required: true,
                        message: 'Please enter the license key!',
                    }
                ]}>
                    <TextArea rows={8} autoFocus disabled={validating}/>
                </Form.Item>

                {errs.length > 0 && <div style={{marginBottom: "2em"}}>{errs.map((err, index) => <Alert key={index} message={err} type="error" showIcon style={{marginBottom: "0.25em"}}/>)}</div>}
                <Form.Item>
                    <Button type="primary" loading={validating} htmlType="submit" >Inspect License</Button>
                </Form.Item>
            </Form>

            {!!licenseValidatingResult &&
                <LicenseDetails result={licenseValidatingResult}/>
            }
        </PageSkeleton>
    );
};

ValidateLicense.propTypes = {
    validateLicense: PropTypes.func.isRequired,
    license: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    license: state.license
});

export default connect(mapStateToProps, {validateLicense})(ValidateLicense);