import React from 'react';
import {Menu, Avatar, Dropdown, Space} from "antd";
import {useAuth0} from "@auth0/auth0-react";
import LogoutButton from "./LogoutButton";

/**
 * The profile picture with the profile context menu.
 */
const ProfileContextMenu = () => {
    const { user } = useAuth0();
    const {nickname} = user;
    const {picture} = user;

    const menu = (
        <Menu>
            <Menu.Item>
                <LogoutButton/>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} placement="bottomRight" trigger="click">
            <a href="#!" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <Space>
                    {picture ? (<Avatar src={picture}/>) : (<i className="far fa-user"/>)}
                    {nickname}
                    <i className="fas fa-angle-down"/>
                </Space>
            </a>
        </Dropdown>
    );
};

export default ProfileContextMenu;