import React, {Fragment} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Auth0Provider} from "@auth0/auth0-react";
import 'antd/dist/antd.css';
import './App.css';
import Navbar from "./components/navigation/Navbar";
import Home from "./components/pages/Home";
import SignExtension from "./components/pages/SignExtension";
import GenerateDevLic from "./components/pages/GenerateDevLic";
import ValidateLicense from "./components/pages/ValidateLicense";
import AuditHistory from "./components/pages/AuditHistory";
import PrivateRoute from "./components/routing/PrivateRoute";
import EngAdminRoute from "./components/routing/EngAdminRoute";
import {Col, Layout, Row} from "antd";
import NotFound from "./components/pages/NotFound";
import {Provider} from "react-redux";
import store from "./store";

const AUTH0_DOMAIN = "rapidminer.auth0.com";
const AUTH0_CLIENT_ID = "YeuhhKoIWYSWaXRa15Ml51LeA1bJxn5e";
const AUTH0_AUDIENCE = "https://engineering-tools.rapidminer.com/api";

const App = () => {
  return (
      <Auth0Provider
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_CLIENT_ID}
          audience={AUTH0_AUDIENCE}
          redirectUri={window.location.origin}
          useRefreshTokens={true}
          cacheLocation={"localstorage"}
      >
          <Provider store={store}>
              <Router>
                  <Fragment>
                      <Navbar/>
                      <Layout>
                          <Layout.Content>
                              <Switch>
                                  <Route exact path="/" component={Home}/>
                                  <PrivateRoute exact path="/sign-extension" component={SignExtension}/>
                                  <PrivateRoute exact path="/generate-development-lic" component={GenerateDevLic}/>
                                  <PrivateRoute exact path="/validate-license" component={ValidateLicense}/>
                                  <EngAdminRoute exact path="/audit-history" component={AuditHistory}/>
                                  {/*map everything except /api paths to 404 page*/}
                                  <PrivateRoute path={new RegExp("^(?!.*\\/api\\/).*$")} component={NotFound}/>
                              </Switch>
                          </Layout.Content>
                          <Layout.Footer style={{paddingBottom: "0.5em"}}>
                              <Row justify="end">
                                  <Col>
                                      Version 1.0.3.4
                                  </Col>
                              </Row>
                              <Row justify="end">
                                  <Col>
                                      &copy; RapidMiner 2020. All rights reserved.
                                  </Col>
                              </Row>
                          </Layout.Footer>
                      </Layout>
                  </Fragment>
              </Router>
          </Provider>
      </Auth0Provider>
  );
}

export default App;
