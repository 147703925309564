/**
 * Extracts the error messages from an error object in the format of { error_key: error_value} and puts all error messages into an error array
 * @param errObject
 * @return the array of error messages, can be empty
 */
export const convertErrorObjectToArray = errObject => {
    const errs = [];
    if (!!errObject) {
        Object.keys(errObject).forEach(key => errs.push(errObject[key]));
    }

    return errs;
}

/**
 * Extracts an error object from the axios error handling. ASYNC!
 * @param err the axios error object
 * @return {{error: *}} our own error object
 */
export const getErrorsFromResponse = async err => {
    let errToDispatch;
    if (err.response) {
        switch (err.response.status) {
            case 500:
                errToDispatch = {error: "Something went wrong. Please try again later."};
                break;
            case 400:
                // 400 = Bad Request, we should have proper JSON error
                // the error is treated as a Blob as well, so we have to convert it back to JSON
                errToDispatch = await new Response(err.response.data).json();
                break;
            default:
                errToDispatch = {error: `Request failed: ${err.response.status} - ${err.response.statusText}`};
        }
    } else {
        // no response, e.g. a timeout
        errToDispatch = {error: err.message};
    }

    return errToDispatch;
}