import React, {Fragment} from 'react';
import {Button, Modal, Tag} from "antd";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";
import Title from "antd/lib/typography/Title";
import SearchableTable from "./SearchableTable";
import {getSignedExtensions} from "../../actions/auditActions";

const SignedExtensionHistory = ({getSignedExtensions, audit: {signedExtensions, loadingSignedExtensions, errorSignedExtensions}}) => {
    const {getAccessTokenSilently} = useAuth0();
    const columns = [
        {
            title: "Name",
            dataIndex: "extensionName",
            key: "extensionName",
            width: "20%",
            sorter: {
                compare: (a, b) => a.extensionName.localeCompare(b.extensionName)
            },
        },
        {
            title: "Extension ID",
            dataIndex: "extensionId",
            key: "extensionId",
            sorter: {
                compare: (a, b) => a.extensionId.localeCompare(b.extensionId)
            }
        },
        {
            title: "Version",
            dataIndex: "version",
            key: "version",
            sorter: {
                compare: (a, b) => a.version.localeCompare(b.version)
            },
            align: "center",
            render: version => {
                const isPreReleaseRegex = new RegExp(".*(-ALPHA\\d*|-BETA\\d*)");
                const isSnapshot = version.endsWith("-SNAPSHOT");
                const isPreRelease = version.match(isPreReleaseRegex) !== null;
                let color;
                if (isSnapshot) {
                    // snapshot version
                    color = "rgba(139,139,139,0.87)";
                } else if (isPreRelease) {
                    // alpha/beta version
                    color = "#87d068";
                } else {
                    // release version
                    color = "#2db7f5";
                }
                return (
                    <Tag style={{textAlign: "center"}} color={color}>
                        {version}
                    </Tag>
                );
            }
        },
        {
            title: "Signed",
            dataIndex: "signedAt",
            key: "signedAt",
            sorter: {
                compare: (a, b) => Date.parse(a.signedAt) - Date.parse(b.signedAt)
            },
            render: signed => {
                const d = new Date(signed);
                return (
                    <span>{d.toLocaleDateString()} {d.toLocaleTimeString()}</span>
                )
            }
        },
        {
            title: "More Info",
            dataIndex: "extensionChecksum",
            key: "info",
            render: extensionChecksum => (
                <Button type="link" onClick={() => {
                    const clickedExt = signedExtensions.find(ext => ext.extensionChecksum === extensionChecksum);
                    let size = Math.round(clickedExt.size / 1024 / 1024);
                    if (size === 0) {
                        size = "<1";
                    }
                    Modal.info({
                        title: 'Extension Details:',
                        content: (
                            <div>
                                <div style={{fontSize: "small", marginTop: "2em", marginBottom: "0.4em"}}><strong>Name:</strong> {clickedExt.extensionName}</div>
                                <div style={{fontSize: "small", marginBottom: "0.4em"}}><strong>ID:</strong> {clickedExt.extensionId}</div>
                                <div style={{fontSize: "small", marginBottom: "0.4em"}}><strong>Version:</strong> {clickedExt.version}</div>
                                <div style={{fontSize: "small", marginBottom: "0.4em"}}><strong>Size:</strong> {size}MB</div>
                                <div style={{fontSize: "small", marginBottom: "0.4em"}}><strong>SHA-1:</strong> {clickedExt.extensionChecksum}</div>
                                <div style={{fontSize: "small", marginBottom: "1em"}}><strong>Signed By:</strong> {clickedExt.signedBy}</div>
                            </div>
                        ),
                        onOk() {},
                    });
                }}>View Details</Button>
            )
        }
    ];

    const doSearch = async search => {
        getSignedExtensions(search, await getAccessTokenSilently());
    };

    return (
        <Fragment>
            <Title level={3}>
                Signed Extensions
            </Title>

            <SearchableTable columns={columns} data={signedExtensions} doSearch={doSearch} loading={loadingSignedExtensions} error={errorSignedExtensions} emptyDataTextFiltered="All signed extensions filtered out" emptyDataTextNoData="No extensions signed yet"/>
        </Fragment>
    );
};

SignedExtensionHistory.propTypes = {
    getSignedExtensions: PropTypes.func.isRequired,
    audit: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    audit: state.audit
});

export default connect(mapStateToProps, {getSignedExtensions})(SignedExtensionHistory);