// LICENSE ACTIONS
export const GENERATE_LICENSE = "GENERATE_LICENSE";
export const SET_GENERATING = "SET_GENERATING";
export const GENERATION_ERROR = "GENERATION_ERROR";
export const VALIDATE_LICENSE = "VALIDATE_LICENSE";
export const SET_VALIDATING = "SET_VALIDATING";
export const VALIDATION_ERROR = "VALIDATION_ERROR";

// SIGN EXTENSION ACTIONS
export const SIGN_EXTENSION = "SIGN_EXTENSION";
export const SET_SIGNING = "SET_SIGNING";
export const SET_SIGNING_UPLOAD_PERCENTAGE = "SET_SIGNING_UPLOAD_PERCENTAGE";
export const SET_SIGNING_WAITING = "SET_SIGNING_WAITING";
export const SIGNING_ERROR = "SIGNING_ERROR";

// AUDIT ACTIONS
export const SET_AUDIT_LICENSES = "SET_AUDIT_LICENSES";
export const AUDIT_LICENSES_ERROR = "AUDIT_LICENSES_ERROR";
export const AUDIT_LICENSES_LOADING = "AUDIT_LICENSES_LOADING";
export const SET_AUDIT_SIGNED_EXTENSIONS = "SET_AUDIT_SIGNED_EXTENSIONS";
export const AUDIT_SIGNED_EXTENSIONS_ERROR = "AUDIT_SIGNED_EXTENSIONS_ERROR";
export const AUDIT_SIGNED_EXTENSIONS_LOADING = "AUDIT_SIGNED_EXTENSIONS_LOADING";