import {
    AUDIT_LICENSES_ERROR,
    SET_AUDIT_LICENSES,
    AUDIT_LICENSES_LOADING, AUDIT_SIGNED_EXTENSIONS_LOADING, SET_AUDIT_SIGNED_EXTENSIONS, AUDIT_SIGNED_EXTENSIONS_ERROR
} from "./types";
import axios from "axios";
import {getErrorsFromResponse} from "../util/ErrorHandling";

export const getGeneratedLicenses = (filter, jwtToken) => async dispatch => {
    try {
        dispatch(setLoadingLicenses());

        const response = await axios.get(`/api/license?search=${filter}`, {
            timeout: 10000,
            timeoutErrorMessage: "The server could not be reached. Please try again later.",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${jwtToken}`
            }
        });

        dispatch({
            type: SET_AUDIT_LICENSES,
            payload: response.data
        });
    } catch (err) {
        dispatch({
            type: AUDIT_LICENSES_ERROR,
            payload: await getErrorsFromResponse(err)
        });
    }
};

export const getSignedExtensions = (filter, jwtToken) => async dispatch => {
    try {
        dispatch(setLoadingSignedExtensions());

        const response = await axios.get(`/api/extensions/sign/all?search=${filter}`, {
            timeout: 10000,
            timeoutErrorMessage: "The server could not be reached. Please try again later.",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${jwtToken}`
            }
        });

        dispatch({
            type: SET_AUDIT_SIGNED_EXTENSIONS,
            payload: response.data
        });
    } catch (err) {
        dispatch({
            type: AUDIT_SIGNED_EXTENSIONS_ERROR,
            payload: await getErrorsFromResponse(err)
        });
    }
};

const setLoadingLicenses = () => {
    return {
        type: AUDIT_LICENSES_LOADING
    };
}

const setLoadingSignedExtensions = () => {
    return {
        type: AUDIT_SIGNED_EXTENSIONS_LOADING
    };
}
